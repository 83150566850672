<template>
  <div id="terms">
    <section>
      <div class="Collapse-bar">
        <div class="container">
          <h2>{{ translation.translate("terms", "terms-title") }}</h2>
          <div class="accordion mt-5" id="accordionExample">
            <div class="row">
              <div class="col-lg-6">
                <div class="accordion-item item-acor border-pos col-lg-12">
                  <h2
                    class="accordion-header padding-none border-pos span-possibilities"
                    data-toggle="collapse"
                    href="#collapseExample1"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample1"
                  >
                    <button
                      class="accordion-button item-acor-header collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span>{{
                        translation.translate("terms", "terms-two-title")
                      }}</span>
                    </button>
                  </h2>
                  <div
                    id="collapseExample1"
                    class="accordion-collapse collapse"
                  >
                    <div class="accordion-body">
                      <p class="light">
                        {{ translation.translate("terms", "terms-two-first") }}
                      </p>
                      <p class="light">
                        {{ translation.translate("terms", "terms-two-second") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item item-acor border-pos col-lg-12">
                  <h2
                    class="accordion-header padding-none border-pos span-possibilities"
                    data-toggle="collapse"
                    href="#collapseExample2"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample2"
                  >
                    <button
                      class="accordion-button item-acor-header collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span>{{
                        translation.translate("terms", "terms-three-title")
                      }}</span>
                    </button>
                  </h2>
                  <div
                    id="collapseExample2"
                    class="accordion-collapse collapse"
                  >
                    <div class="accordion-body">
                      <p class="light">
                        {{ translation.translate("terms", "terms-three") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item item-acor border-pos col-lg-12">
                  <h2
                    class="accordion-header padding-none border-pos span-possibilities"
                    data-toggle="collapse"
                    href="#collapseFour"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <button
                      class="accordion-button item-acor-header collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <span>{{
                        translation.translate("terms", "terms-four-title")
                      }}</span>
                    </button>
                  </h2>
                  <div id="collapseFour" class="accordion-collapse collapse">
                    <div class="accordion-body">
                      <p class="light">
                        {{ translation.translate("terms", "terms-four-first") }}
                      </p>
                      <p class="light">
                        {{
                          translation.translate("terms", "terms-four-second")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div id="hybrid-position" class="accordion-item item-acor border-pos col-lg-12">
                  <h2
                    class="accordion-header padding-none border-pos span-possibilities"
                    data-toggle="collapse"
                    href="#collapseEight"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    <button
                      class="accordion-button item-acor-header collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      <span>{{
                        translation.translate("terms", "hybrid-document")
                      }}</span>
                    </button>
                  </h2>
                  <div id="collapseEight" class="accordion-collapse collapse">
                    <div class="accordion-body">
                      <p class="light">
                        {{
                          translation.translate(
                            "terms",
                            "hybrid-document-terms"
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item item-acor border-pos col-lg-12">
                  <h2
                    class="accordion-header padding-none border-pos span-possibilities"
                    data-toggle="collapse"
                    href="#collapse8"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapse8"
                  >
                    <button
                      class="accordion-button item-acor-header collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse8"
                      aria-expanded="false"
                      aria-controls="collapse8"
                    >
                      <span>{{
                        translation.translate("terms", "terms-eight-title")
                      }}</span>
                    </button>
                  </h2>
                  <div id="collapse8" class="accordion-collapse collapse">
                    <div class="accordion-body">
                      <p class="light">
                        <span class="dark font-weight-bolder"></span
                        >{{ translation.translate("terms", "terms-eight") }}
                      </p>
                    </div>
                  </div>
                </div>


              </div>
              <div class="col-lg-6">
                <div class="accordion-item item-acor border-pos col-lg-12">
                  <h2
                    class="accordion-header padding-none border-pos span-possibilities"
                    data-toggle="collapse"
                    href="#collapse11"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapse11"
                  >
                    <button
                      class="accordion-button item-acor-header collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse11"
                      aria-expanded="false"
                      aria-controls="collapse11"
                    >
                      <span>{{
                        translation.translate("terms", "terms-one-title")
                      }}</span>
                    </button>
                  </h2>
                  <div id="collapse11" class="accordion-collapse collapse">
                    <div class="accordion-body">
                      <p class="light">
                        {{ translation.translate("terms", "terms-one") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item item-acor border-pos col-lg-12">
                  <h2
                    class="accordion-header padding-none border-pos span-possibilities"
                    data-toggle="collapse"
                    href="#collapse12"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapse12"
                  >
                    <button
                      class="accordion-button item-acor-header collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse12"
                      aria-expanded="false"
                      aria-controls="collapse12"
                    >
                      <span>{{
                        translation.translate("terms", "terms-five-title")
                      }}</span>
                    </button>
                  </h2>
                  <div id="collapse12" class="accordion-collapse collapse">
                    <div class="accordion-body">
                      <p class="light">
                        {{
                          translation.translate(
                            "terms",
                            "special-contractors-term"
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item item-acor border-pos col-lg-12">
                  <h2
                    class="accordion-header padding-none border-pos span-possibilities"
                    data-toggle="collapse"
                    href="#collapse13"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapse13"
                  >
                    <button
                      class="accordion-button item-acor-header collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse13"
                      aria-expanded="false"
                      aria-controls="collapse13"
                    >
                      <span>{{
                        translation.translate("terms", "terms-six-title")
                      }}</span>
                    </button>
                  </h2>
                  <div id="collapse13" class="accordion-collapse collapse">
                    <div class="accordion-body">
                      <p class="light">
                        {{ translation.translate("terms", "terms-six") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item item-acor border-pos col-lg-12">
                  <h2
                    class="accordion-header padding-none border-pos span-possibilities"
                    data-toggle="collapse"
                    href="#collapse14"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapse14"
                  >
                    <button
                      class="accordion-button item-acor-header collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse14"
                      aria-expanded="false"
                      aria-controls="collapse14"
                    >
                      <span>{{
                        translation.translate("terms", "terms-seven-title")
                      }}</span>
                    </button>
                  </h2>
                  <div id="collapse14" class="accordion-collapse collapse">
                    <div class="accordion-body">
                      <p class="light">
                        <span class="dark font-weight-bolder"></span
                        >{{ translation.translate("terms", "terms-seven") }}
                      </p>
                    </div>
                  </div>

                  
                </div>
                <div id="collapse15-container" class="accordion-item item-acor border-pos col-lg-12">
                  <h2
                    class="accordion-header padding-none border-pos span-possibilities"
                    data-toggle="collapse"
                    href="#collapse15"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapse15"
                  >
                    <button
                      class="accordion-button item-acor-header collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse15"
                      aria-expanded="false"
                      aria-controls="collapse15"
                    >
                      <span>{{
                        translation.translate("terms", "terms-ten-title")
                      }}</span>
                    </button>
                  </h2>
                  <div id="collapse15" class="accordion-collapse collapse">
                    <div class="accordion-body">
                      <p class="light">
                        <span class="dark font-weight-bolder"></span
                        >{{ translation.translate("terms", "terms-ten") }}
                      </p>
                    </div>
                  </div>

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import translate from "../../translation/translate";
export default {
  name: "terms",
  data() {
    return {
      translation: translate,
    };
  },
};
</script>

<style>
</style>