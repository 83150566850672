<template>
  <div id="archive-manager-subscription-component-template">
    <h2>{{ translation.translate("hybridDocument", "hybrid-document") }}</h2>
    <div class="archive-manager-subscription-component justify-content-center">
      <div class="cards col-lg-10 col-12">
        <div class="paket-info py-5">
          <div class="archieve-card">
            <h2 class="mb-3" style="font-weight: bold; padding-top: 30px !important">
              {{ translation.translate("Price", "hybridTitle") }}
            </h2>
            <div class="d-lg-flex justify-content-around px-4">
              <div class="py-3 col-lg-4 col-12">
                <h6 class="">{{ translation.translate('Price', 'for') }} 1 {{ translation.translate('Price', 'forThings') }}</h6>
                <h1 style="font-weight: bold; color: #45b411; padding-top: 20px;">11 500</h1>
                <h6>
                  {{
                    translation.translate(
                      "Price",
                      "som"
                    )
                  }}
                </h6>
                <form action="http://app.faktura.uz/tariff/index" target="_blank">
                  <input class="arxiv_manager mt-4 mx-0" type="submit"
                    :value="translation.translate('Price', 'activate')">
                </form>
              </div>
              <div class="py-3  col-lg-4 col-12 border-mobile">
                <div class="position-relative">
                  <p class="position-absolute mb-0 soon">{{ translation.translate('Price', 'soon') }}</p>
                </div>
                <h6 class="">{{ translation.translate('Price', 'for') }} 100 {{ translation.translate('Price', 'forThings') }}</h6>

                <h1 style="font-weight: bold; color: #45b411;  padding-top: 20px;">1 125 000</h1>
                <h6>
                  {{
                    translation.translate(
                      "Price",
                      "som"
                    )
                  }}
                </h6><form action="" target="_blank">
                  <input class="arxiv_manager mt-4 mx-0" style="background-color: darkgray;" disabled type="submit"
                    :value="translation.translate('Price', 'activate')">
                </form>
              </div>
              <div class="py-3  col-lg-4 col-12">
                <div class="position-relative">
                  <p class="position-absolute mb-0 soon">{{ translation.translate('Price', 'soon') }}</p>
                </div>
                <h6 class=""> {{ translation.translate('Price', 'for') }} 1 000 {{ translation.translate('Price', 'forThings') }}</h6>
                <h1 style="font-weight: bold; color: #45b411;  padding-top: 20px;">10 900 000</h1>
                <h6>
                  {{
                    translation.translate(
                      "Price",
                      "som"
                    )
                  }}
                </h6>
                <form action="" target="_blank">
                  <input class="arxiv_manager mt-4 mx-0" style="background-color: darkgray;" disabled type="submit"
                    :value="translation.translate('Price', 'activate')">
                </form>
              </div>
            </div>
            <div class="p-4 ">
              <h6 class="mb-0" style="font-weight: 500;">
                <span class="position-relative mr-3"><span class=" position-absolute color-green" style="font-size: 25px; top: 0px; left: 0px;">*</span></span>
                {{ translation.translate("Price", "hybridPriceAddition") }}</h6>
            </div>
            <div class="px-4"><h6 class="px-lg-4" style="font-weight: 500;font-size: 14px;">{{ translation.translate("Price", "hybridPriceAddition2") }}</h6></div>
            <div class="archieve-urls pt-4 pb-5">
              <a href="#hybrid-position"  @click="callGtag('Архив Менеджер,archive_manager_button,click')">
                <u>{{
                  translation.translate(
                    "hybridDocument",
                    "hybrid-terms-url-text"
                  )
                }}</u>
              </a>
              &ensp;
              <a href="https://youtu.be/tFsJIt2rHqY">
                <u>
                  {{
                    translation.translate(
                      "archievemanager",
                      "archieve-instruction-url-text"
                    )
                  }}</u>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2 class="tarif-title">
      {{ translation.translate("Price", "complex-solvings") }}
    </h2>
    <div class="archive-manager-subscription-component">
      <div class="cards">
        <div class="paket-info">
          <div class="archieve-card">
            <h2 style="font-weight: bold; padding-top: 30px !important">
              {{ translation.translate("archievemanager", "archieve-manager") }}
            </h2>
            <h1 style="font-weight: bold; color: #45b411">300 000</h1>
            <h6>
              {{
                translation.translate(
                  "archievemanager",
                  "archieve-manager-price"
                )
              }}
            </h6>
            <ul class="archive-manager-info" style="margin: 10px 0px !important">
              <li>
                {{
                  translation.translate(
                    "archievemanager",
                    "archieve-term-one-first"
                  )
                }}
                <span style="color: #45b411">{{
                  translation.translate(
                    "archievemanager",
                    "archieve-term-one-second"
                  )
                }}</span>
              </li>
              <li>
                {{
                  translation.translate("archievemanager", "archieve-term-two")
                }}
              </li>
              <li>
                {{
                  translation.translate(
                    "archievemanager",
                    "archieve-term-three"
                  )
                }}
              </li>
              <li>
                {{
                  translation.translate("archievemanager", "archieve-term-four")
                }}
              </li>
              <li>
                {{
                  translation.translate("archievemanager", "archieve-term-five")
                }}
              </li>
            </ul>
            <form action="http://app.faktura.uz/tariff/index" target="_blank">

              <input class="arxiv_manager" type="submit"
                :value="translation.translate('archievemanager', 'archieve-button-free-trial')">
            </form>

            <div class="archieve-urls">
              <a @click="callGtag('Архив Менеджер,archive_manager_button,click')" target="_blank"
                href="https://edo.faktura.uz/resource/FakturaArxivManager.zip" download>
                <u>{{
                  translation.translate(
                    "archievemanager",
                    "archieve-download-url-text"
                  )
                }}</u>
              </a>
              &ensp;
              <a class="" href="https://t.me/fakturauz_info/814">
                <u>
                  {{
                    translation.translate(
                      "archievemanager",
                      "archieve-instruction-url-text"
                    )
                  }}</u>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="cards">
        <div class="paket-info center-paket" style="">
          <div class="archieve-card">
            <h2 style="font-weight: bold; padding-top: 30px !important">
              {{ translation.translate("hybridDocument", "hybrid-document") }}
            </h2>
            <h1 style="font-weight: bold; color: #ff1616">9 000</h1>
            <h6>
              {{
                translation.translate("hybridDocument", "hybrid-document-price")
              }}
            </h6>
            <ul class="archive-manager-info" style="margin: 10px 0px !important">
              <li>
                {{ translation.translate("hybridDocument", "hybrid-term-one") }}
              </li>
              <li>
                {{ translation.translate("hybridDocument", "hybrid-term-two") }}
              </li>
              <li>
                {{
                  translation.translate("hybridDocument", "hybrid-term-three")
                }}
              </li>
              <li>
                {{
                  translation.translate("hybridDocument", "hybrid-term-four")
                }}
              </li>
              <li>
                {{
                  translation.translate("hybridDocument", "hybrid-term-five")
                }}
              </li>
              <li>
                {{ translation.translate("hybridDocument", "hybrid-term-six") }}
              </li>
            </ul>
            <form action="https://app.faktura.uz/ru/hybriddocument/list" target="_blank">
              <input class="hybrid_pochta" type="submit"
                :value="translation.translate('hybridDocument', 'hybrid-button')">
            </form>
            <div class="archieve-urls">
              <a href="#hybrid-position">
                <u>{{
                  translation.translate(
                    "hybridDocument",
                    "hybrid-terms-url-text"
                  )
                }}</u>
              </a>
              &ensp;
              <a href="https://youtu.be/tFsJIt2rHqY">
                <u>
                  {{
                    translation.translate(
                      "hybridDocument",
                      "hybrid-instruction-url-text"
                    )
                  }}</u>
              </a>
            </div>
          </div>
        </div>
      </div> -->
      <div class="cards">
        <div class="paket-info center-paket h-100">
          <div class="archieve-card h-100 d-flex justify-content-between flex-column">
            <h2 style="font-weight: bold; padding-top: 30px !important">
              {{ translation.translate("archievemanager", "special-reports") }}
            </h2>
            <h1 style="font-weight: bold; color: #45b411">300 000</h1>
            <h6>
              {{
                translation.translate(
                  "archievemanager",
                  "archieve-manager-price"
                )
              }}
            </h6>
            <ul class="archive-manager-info" style="margin: 10px 0px !important">
              <li>
                {{ translation.translate("special-reports", "special-reports-term-one") }}
              </li>
              <li>
                {{ translation.translate("special-reports", "special-reports-term-two") }}
              </li>
              <li>
                {{ translation.translate("special-reports", "special-reports-term-three") }}
              </li>
            </ul>
            <form action="http://app.faktura.uz/tariff/index" target="_blank">
              <input class="card_monitoring" type="submit"
                :value="translation.translate('hybridDocument', 'hybrid-button')">
            </form>
            <div class="archieve-urls">
              <a>
                <u>{{
                  translation.translate("archievemanager", "archieve-download-url-text")
                }}</u>
              </a>
              &ensp;
              <a>
                <u>
                  {{
                    translation.translate(
                      "corpCard",
                      "corpcard-instruction-url-text"
                    )
                  }}</u>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="cards">
        <div class="paket-info">
          <div class="archieve-card">
            <h2 style="font-weight: bold; padding-top: 30px !important">
              {{ translation.translate("corpCard", "corp-card") }}
            </h2>
            <h1 style="font-weight: bold; color: #45b411">10 000</h1>
            <h6>
              {{ translation.translate("corpCard", "corp-card-price-text") }}
            </h6>
            <ul class="archive-manager-info" style="margin: 10px 0px !important">
              <li>
                {{ translation.translate("corpCard", "corpcard-term-one") }}
              </li>
              <li>
                {{ translation.translate("corpCard", "corpcard-term-two") }}
              </li>
              <li>
                {{ translation.translate("corpCard", "corpcard-term-three") }}
              </li>
              <li>
                {{ translation.translate("corpCard", "corpcard-term-four") }}
              </li>
              <li>
                {{ translation.translate("corpCard", "corpcard-term-five") }}
              </li>
              <li>
                {{ translation.translate("corpCard", "corpcard-term-six") }}
              </li>
            </ul>
            <form action="https://app.faktura.uz/ru/history/index#kpk" target="_blank">
              <input class="card_monitoring" type="submit"
                :value="translation.translate('corpCard', 'corpcard-button')">
            </form>
            <div class="archieve-urls">
              <a href="#hybrid-position">
                <u>{{
                  translation.translate("corpCard", "corpcard-terms-url-text")
                }}</u>
              </a>
              &ensp;
              <a href="https://youtu.be/jURNmXF1lGU">
                <u>
                  {{
                    translation.translate(
                      "corpCard",
                      "corpcard-instruction-url-text"
                    )
                  }}</u>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div  class="archive-manager-subscription-component">
      <div class="cards">
        <div class="paket-info">
          <div class="archieve-card">
            <h2 style="font-weight: bold; padding-top: 30px !important">
              {{ translation.translate("archievemanager", "akt-sverki") }}
            </h2>
            <h1 style="font-weight: bold; color: #45b411">30 000</h1>
            <h6>
              {{
                translation.translate(
                  "Price",
                  "som"
                )
              }}/{{
                translation.translate(
                  "Price",
                  "generate"
                )
              }}
            </h6>
            <ul class="archive-manager-info" style="margin: 10px 0px !important">
              <li>
                {{
                  translation.translate(
                    "aktSverki",
                    "aktSverki-term-one"
                  )
                }}
              </li>
              <li>
                {{
                  translation.translate("aktSverki", "aktSverki-term-two")
                }}
              </li>
              <li>
                {{
                  translation.translate(
                    "aktSverki",
                    "aktSverki-term-three"
                  )
                }}
              </li>
              <li>
                {{
                  translation.translate("aktSverki", "aktSverki-term-four")
                }}
              </li>
            </ul>
            <form action="http://app.faktura.uz/tariff/index" target="_blank">

              <input class="arxiv_manager" type="submit"
                :value="translation.translate('hybridDocument', 'hybrid-button')">
            </form>

            <div class="archieve-urls ">
              <a @click="callGtag('Архив Менеджер,archive_manager_button,click')">
                <u>{{
                  translation.translate(
                    "archievemanager",
                    "archieve-download-url-text"
                  )
                }}</u>
              </a>
              &ensp;
              <a class="">
                <u>
                  {{
                    translation.translate(
                      "archievemanager",
                      "archieve-instruction-url-text"
                    )
                  }}</u>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="cards">
        <div class="paket-info center-paket h-100 " style="">
          <div class="archieve-card h-100 d-flex justify-content-between flex-column">
            <h2 style="font-weight: bold; padding-top: 30px !important">
              {{ translation.translate("archievemanager", "monitoring-contract") }}
            </h2>
            <h1 style="font-weight: bold; color: #45b411">5 000 000</h1>
            <h6>
              {{
                translation.translate(
                  "archievemanager",
                  "archieve-manager-price"
                )
              }}
            </h6>
            <ul class="archive-manager-info" style="margin: 10px 0px !important">
              <li>
                {{
                  translation.translate(
                    "monitoringContract",
                    "monitoringContract-term-one"
                  )
                }}
              </li>
              <li>
                {{
                  translation.translate("monitoringContract", "monitoringContract-term-two")
                }}
              </li>
              <li>
                {{
                  translation.translate(
                    "monitoringContract",
                    "monitoringContract-term-three"
                  )
                }}
              </li>
              <li>
                {{
                  translation.translate("monitoringContract", "monitoringContract-term-four")
                }}
              </li>
            </ul>
            <form action="http://app.faktura.uz/tariff/index" target="_blank">
              <input class="hybrid_pochta" type="submit"
                :value="translation.translate('hybridDocument', 'hybrid-button')">
            </form>
            <div class="archieve-urls">
              <a>
                <u>{{
                  translation.translate(
                    "hybridDocument",
                    "hybrid-terms-url-text"
                  )
                }}</u>
              </a>
              &ensp;
              <a>
                <u>
                  {{
                    translation.translate(
                      "hybridDocument",
                      "hybrid-instruction-url-text"
                    )
                  }}</u>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="cards">
        <div class="paket-info">
          <div class="archieve-card">
            <h2 style="font-weight: bold; padding-top: 30px !important">
              {{ translation.translate("archievemanager", "special-reports") }}
            </h2>
            <h1 style="font-weight: bold; color: #45b411">300 000</h1>
            <h6>
              {{
                translation.translate(
                  "archievemanager",
                  "archieve-manager-price"
                )
              }}
            </h6>
            <ul class="archive-manager-info" style="margin: 10px 0px !important">
              <li>
                {{ translation.translate("special-reports", "special-reports-term-one") }}
              </li>
              <li>
                {{ translation.translate("special-reports", "special-reports-term-two") }}
              </li>
              <li>
                {{ translation.translate("special-reports", "special-reports-term-three") }}
              </li>
            </ul>
            <form action="http://app.faktura.uz/tariff/index" target="_blank">
              <input class="card_monitoring" type="submit"
                :value="translation.translate('hybridDocument', 'hybrid-button')">
            </form>
            <div class="archieve-urls">
              <a>
                <u>{{
                  translation.translate("archievemanager", "archieve-download-url-text")
                }}</u>
              </a>
              &ensp;
              <a>
                <u>
                  {{
                    translation.translate(
                      "corpCard",
                      "corpcard-instruction-url-text"
                    )
                  }}</u>
              </a>
            </div>
          </div>
        </div>
      </div> -->
      <div class="cards">
        <div class="paket-info">
          <div class="archieve-card">
            <h2 class="px-4" style="font-weight: bold; padding-top: 30px !important">
              {{ translation.translate("archievemanager", "monitoring-counter") }}
            </h2>
            <h1 style="font-weight: bold; color: #45b411">100 000</h1>
            <h6>
              {{
                translation.translate(
                  "Price",
                  "som"
                )
              }}/{{
                translation.translate(
                  "Price",
                  "generate"
                )
              }}
            </h6>
            <ul class="archive-manager-info" style="margin: 10px 0px !important">
              <li>
                {{ translation.translate("monitoring-counter", "monitoring-counter-term-one") }}
              </li>
              <li>
                {{ translation.translate("monitoring-counter", "monitoring-counter-term-two") }}
              </li>
              <li>
                {{ translation.translate("monitoring-counter", "monitoring-counter-term-three") }}
              </li>
            </ul>
            <form action="http://app.faktura.uz/tariff/index" target="_blank">

              <input class="arxiv_manager" type="submit"
                :value="translation.translate('hybridDocument', 'hybrid-button')">
            </form>

            <div class="archieve-urls">
              <a @click="callGtag('Архив Менеджер,archive_manager_button,click')">
                <u>{{
                  translation.translate(
                    "archievemanager",
                    "archieve-download-url-text"
                  )
                }}</u>
              </a>
              &ensp;
              <a class="">
                <u>
                  {{
                    translation.translate(
                      "archievemanager",
                      "archieve-instruction-url-text"
                    )
                  }}</u>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="archive-manager-subscription-component justify-content-center">
      <div class="cards col-lg-10 col-12">
        <div class="paket-info py-5">
          <div class="archieve-card">
            <h2 style="font-weight: bold; padding-top: 30px !important">
              Mass Mailing
            </h2>
            <div class="d-lg-flex justify-content-around px-4">
              <div class="py-3 col-lg-4 col-12">
                <h6 class=""> {{ translation.translate("mass-mailings", "letter-Faktura") }}</h6>
                <h1 style="font-weight: bold; color: #45b411; padding-top: 20px;">1 000</h1>
                <h6>
                  {{
                    translation.translate(
                      "Price",
                      "som"
                    )
                  }}/{{
                    translation.translate(
                      "Price",
                      "letter"
                    )
                  }}
                </h6>
              </div>
              <div class="py-3  col-lg-4 col-12 border-mobile">
                <h6 class="">{{ translation.translate("mass-mailings", "letter-sms") }}</h6>

                <h1 style="font-weight: bold; color: #45b411;  padding-top: 20px;">300</h1>
                <h6>
                  {{
                    translation.translate(
                      "Price",
                      "som"
                    )
                  }}/{{
                    translation.translate(
                      "Price",
                      "message"
                    )
                  }}
                </h6>
              </div>
              <div class="py-3  col-lg-4 col-12">
                <h6 class="">{{ translation.translate("mass-mailings", "letter-gibrid") }}</h6>
                <h1 style="font-weight: bold; color: #45b411;  padding-top: 20px;">11 500</h1>
                <h6>
                  {{
                    translation.translate(
                      "Price",
                      "som"
                    )
                  }}/{{
                    translation.translate(
                      "Price",
                      "letter"
                    )
                  }}
                </h6>
              </div>
            </div>
            <form action="http://app.faktura.uz/tariff/index" target="_blank">

              <input class="arxiv_manager mt-5" type="submit"
                :value="translation.translate('hybridDocument', 'hybrid-button')">
            </form>

            <div class="archieve-urls pt-4 pb-5">
              <a @click="callGtag('Архив Менеджер,archive_manager_button,click')">
                <u>{{
                  translation.translate(
                    "hybridDocument",
                    "hybrid-terms-url-text"
                  )
                }}</u>
              </a>
              &ensp;
              <a class="">
                <u>
                  {{
                    translation.translate(
                      "archievemanager",
                      "archieve-instruction-url-text"
                    )
                  }}</u>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="archive-manager-subscription-component justify-content-center">
      <div class="cards">
        <div class="paket-info">
          <div class="archieve-card">
            <h2 class="px-4" style="font-weight: bold; padding-top: 30px !important">
              {{ translation.translate("archievemanager", "monitoring-counter") }}
            </h2>
            <h1 style="font-weight: bold; color: #45b411">100 000</h1>
            <h6>
              {{
                translation.translate(
                  "Price",
                  "som"
                )
              }}/{{
                translation.translate(
                  "Price",
                  "generate"
                )
              }}
            </h6>
            <ul class="archive-manager-info" style="margin: 10px 0px !important">
              <li>
                {{ translation.translate("monitoring-counter", "monitoring-counter-term-one") }}
              </li>
              <li>
                {{ translation.translate("monitoring-counter", "monitoring-counter-term-two") }}
              </li>
              <li>
                {{ translation.translate("monitoring-counter", "monitoring-counter-term-three") }}
              </li>
            </ul>
            <form action="http://app.faktura.uz/tariff/index" target="_blank">

              <input class="arxiv_manager" type="submit"
                :value="translation.translate('hybridDocument', 'hybrid-button')">
            </form>

            <div class="archieve-urls">
              <a @click="callGtag('Архив Менеджер,archive_manager_button,click')">
                <u>{{
                  translation.translate(
                    "archievemanager",
                    "archieve-download-url-text"
                  )
                }}</u>
              </a>
              &ensp;
              <a class="">
                <u>
                  {{
                    translation.translate(
                      "archievemanager",
                      "archieve-instruction-url-text"
                    )
                  }}</u>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <h5 class="tarif-title mt-5 mt-xl-2" style="text-align: center; font-weight: 500">
      <a style="color: #45b411" class="price_list_download" href="https://edo.faktura.uz/resource/ПРАЙС-ЛИСТ_10.28.24.pdf" disabled>
        <img src="../assets/images/svg/download.svg" alt="" />
        {{ translation.translate("Price", "downloadPriceList") }}</a>
    </h5>
  </div>
</template>


<script>
import translate from "../../translation/translate";
import mixins from "./../mixin";
export default {
  name: "archieveManager",
  mixins: [mixins],
  data() {
    return {
      translation: translate,
    };
  },
};
</script>

<style>
.soon {
  top: -16px;
  right: -12px;
  background-color: #45b411;
  border-radius: 20px;
  padding: 0px 7px;
  color: white;
  cursor: default;
  font-size: 14px;
}
.archive-manager-subscription-component {
  display: flex;
  margin-right: 10%;
  margin-left: 10%;
}

.archive-manager-subscription-component .archieve-urls {
  flex-direction: row;
  padding: 5% 5% 10% 5%;
}

.archive-manager-subscription-component li {
  text-align: left;
  padding: 5px 40px 5px 5px;
  font-size: 15px;
  font-weight: 600;
  list-style: circle;
}

.archive-manager-subscription-component ul {
  padding: 5px 5px 5px 50px;
}

.archive-manager-subscription-component .cards .paket-info {
  padding: 13%;
}

.archive-manager-subscription-component .cards .center-paket {
  padding-left: 5%;
  padding-right: 5%;
}

.archive-manager-subscription-component .cards {
  width: 33%;
}

.archive-manager-subscription-component .cards .paket-info .archieve-card h4,
h5,
h6 {
  width: 100%;
  margin-top: 10px;
  font-weight: 800;
  color: black;
}

.archive-manager-subscription-component .cards .paket-info .archieve-card h6 {
  border-bottom: none;
  padding-bottom: 10px;
}

.archive-manager-subscription-component .cards .paket-info .archieve-card h4 {
  padding-top: 10px;
  margin: 0 !important;
  font-weight: 800;
}

.archive-manager-subscription-component .cards .paket-info .archieve-card h6 {
  margin: 0;
  font-weight: 800;
}

.archive-manager-subscription-component .cards .paket-info .archieve-card {
  text-align: center;
  background: #f7f7f7;
  box-shadow: rgb(40 40 40 / 20%) 0px 7px 29px 0px;
  border-radius: 10px;
}

.archive-manager-subscription-component .cards .paket-info button:hover {
  background-color: green;
  color: white;
  transition: 0.5s;
}

.archive-manager-subscription-component .cards .paket-info .archieve-card h4,
h5,
h6 {
  width: 100%;
  margin-top: 10px;
  font-weight: 800;
  color: #363636;
}

.archive-manager-subscription-component .paket-info input {
  margin-top: 8%;
  font-weight: 500;
  background-color: #45b411;
  color: white;
  padding: 7px 50px;
  border: none;
  border-radius: 6px;
  margin-left: 16%;
  margin-right: 16%;
  white-space: normal;
}

.archive-manager-subscription-component .paket-info .archieve-card a {
  font-weight: 500 !important;
}

.archive-manager-subscription-component .cards .paket-info {
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.archive-manager-subscription-component .cards .paket-info .danger:hover {
  background-color: red;
  color: white;
  transition: 0.5s;
}

.archive-manager-subscription-component .paket-info .danger {
  font-weight: 500;
  background-color: #e74c3c;
  color: white;
  padding: 7px 50px;
  border: none;
  margin-bottom: 0px;
}

.archieve-card .border-mobile {
  border-left: 2px solid #bdbaba !important;
  border-right: 2px solid #bdbaba !important;
}

@media (max-width: 991px) {
  .archieve-card .border-mobile {
    border-top: 2px solid #bdbaba !important;
    border-bottom: 2px solid #bdbaba !important;
    border-left: none !important;
    border-right: none !important;
  }
  .archive-manager-subscription-component {
    display: block;
    width: 100%;
      margin-left: 0px;
      margin-right: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .archive-manager-subscription-component {
    display: block;
    width: 100%;
      margin-left: 0px;
      margin-right: 0px;
  }

  .archive-manager-subscription-component .cards {
    width: 100%;
  }

  .archive-manager-subscription-component .cards .paket-info {
    padding: 15%;
  }

  .archive-manager-subscription-component .cards .center-paket {
    padding: 15%;
  }
}

@media screen and (max-width: 414px) {
  .soon {
    top: -12px;
  }
  .archive-manager-subscription-component ul {
    padding: 3px 3px 3px 30px;
  }

  .archive-manager-subscription-component {
    display: block;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .archive-manager-subscription-component .cards {
    padding-top: 20px;
    width: 100%;
  }

  .archive-manager-subscription-component .cards .paket-info {
    padding: 15%;
  }

  /* .archive-manager-subscription-component .cards .center-paket {
    padding: 15%;
  } */

  .archive-manager-subscription-component .cards .paket-info {
    width: 90%;
    height: 90%;
    padding: 0px;
    margin: auto;
  }

  /* .archive-manager-subscription-component .cards .center-paket {
    padding: 0px;
  } */

  .archive-manager-subscription-component .cards .paket-info .archieve-card {
    margin: auto !important;
    padding-left: 5px;
    padding-right: 5px;
  }

  .archive-manager-subscription-component .cards .paket-info button {
    padding: 8px 60px;
    margin-top: 8%;
    font-weight: 500;
    background-color: #45b411;
    color: white;
    border: none;
  }
}
</style>
