<template>
  <div id="price">
    <mainheader></mainheader>
    <mainbox></mainbox>
    <ChooseTariffModal></ChooseTariffModal>
    <!-- <specialTariffs ></specialTariffs> -->
    <tariftable></tariftable>
    <section>
      <h5 class="tarif-title" style="text-align: center; font-weight: 500">
        <router-link v-if="this.$route.name == 'price'" :to="{ name: 'price-archive' }" style="color: #45b411"
          class="price_list_download">
          {{ translation.translate("Price", "archive-tariff") }}</router-link>
        <router-link v-if="this.$route.name == 'price-archive'" :to="{ name: 'price' }" style="color: #45b411"
          class="price_list_download">
          {{ translation.translate("Price", "new-tariff") }}</router-link>
      </h5>
    </section>
    <section v-if="this.$route.name == 'price-archive'">
      <h2 class="tarif-title">
        {{ translation.translate("Price", "Packages-title") }}
      </h2>
      <div>
        <div class="packages">
          <div class="cards">
            <div class="card">
              <div class="text-right"></div>
              <h4 style="font-size: 26px">5</h4>
              <h6>{{ translation.translate("Price", "outGoing") }}</h6>
              <h5>
                <strong>0 </strong>
                <span class="summes">{{ translation.translate("Price", "som") }}/{{
                  translation.translate("Price", "month")
                }}</span>
              </h5>
              <input id="buyButton" class="package_5 " type=button disabled
                onClick="location.href='http://app.faktura.uz/ServicePackage/Index'"
                :value="translation.translate('Price', 'buy')">


            </div>
            <div class="card">
              <div class="text-right"></div>
              <h4>50</h4>
              <h6>{{ translation.translate("Price", "outGoing") }}</h6>
              <h5>
                <strong>45 000 </strong>
                <span class="summes">{{ translation.translate("Price", "som") }}/{{
                  translation.translate("Price", "month")
                }}</span>
              </h5>
              <input id="buyButton" class="package_50 " type=button disabled
                onClick="location.href='http://app.faktura.uz/ServicePackage/Index'"
                :value="translation.translate('Price', 'buy')">

            </div>
            <div class="card">
              <div class="text-right"></div>
              <h4>125</h4>
              <h6>{{ translation.translate("Price", "outGoing") }}</h6>
              <h5>
                <strong>105 000 </strong>
                <span class="summes">{{ translation.translate("Price", "som") }}/{{
                  translation.translate("Price", "month")
                }}</span>
              </h5>
              <input id="buyButton" class="package_125 " type=button disabled
                onClick="location.href='http://app.faktura.uz/ServicePackage/Index'"
                :value="translation.translate('Price', 'buy')">

            </div>
            <div class="card">
              <div class="text-right"></div>
              <h4>250</h4>
              <h6>{{ translation.translate("Price", "outGoing") }}</h6>
              <h5>
                <strong>190 000 </strong>
                <span class="summes">{{ translation.translate("Price", "som") }}/{{
                  translation.translate("Price", "month")
                }}</span>
              </h5>
              <input id="buyButton" class="package_250 " type=button disabled
                onClick="location.href='http://app.faktura.uz/ServicePackage/Index'"
                :value="translation.translate('Price', 'buy')">

            </div>
            <div class="card">
              <div class="text-right"></div>
              <h4>500</h4>
              <h6>{{ translation.translate("Price", "outGoing") }}</h6>
              <h5>
                <strong>345 000 </strong>
                <span class="summes">{{ translation.translate("Price", "som") }}/{{
                  translation.translate("Price", "month")
                }}</span>
              </h5>
              <input id="buyButton" class="package_500 " type=button disabled
                onClick="location.href='http://app.faktura.uz/ServicePackage/Index'"
                :value="translation.translate('Price', 'buy')">

            </div>
            <div class="card">
              <div class="text-right"></div>
              <h4>1000</h4>
              <h6>{{ translation.translate("Price", "outGoing") }}</h6>
              <h5>
                <strong>650 000 </strong>
                <span class="summes">{{ translation.translate("Price", "som") }}/{{
                  translation.translate("Price", "month")
                }}</span>
              </h5>
              <input id="buyButton" class="package_1000 " type=button disabled
                onClick="location.href='http://app.faktura.uz/ServicePackage/Index'"
                :value="translation.translate('Price', 'buy')">

            </div>
            <div class="card">
              <div class="text-right"></div>
              <h4>2500</h4>
              <h6>{{ translation.translate("Price", "outGoing") }}</h6>
              <h5>
                <strong>1 500 000 </strong>
                <span class="summes">{{ translation.translate("Price", "som") }}/{{
                  translation.translate("Price", "month")
                }}</span>
              </h5>
              <input id="buyButton" class="package_2500 " type=button disabled
                onClick="location.href='http://app.faktura.uz/ServicePackage/Index'"
                :value="translation.translate('Price', 'buy')">

            </div>
            <div class="card">
              <div class="text-right"></div>
              <h4>5000</h4>
              <h6>{{ translation.translate("Price", "outGoing") }}</h6>
              <h5>
                <strong>2 750 000 </strong>
                <span class="summes">{{ translation.translate("Price", "som") }}/{{
                  translation.translate("Price", "month")
                }}</span>
              </h5>
              <input id="buyButton" class="package_5000 " type=button disabled
                onClick="location.href='http://app.faktura.uz/ServicePackage/Index'"
                :value="translation.translate('Price', 'buy')">

            </div>
          </div>
        </div>
      </div>
    </section>
    <archieveManager></archieveManager>
    <terms></terms>
    <mainfooter></mainfooter>
  </div>
</template>

<script defer>
import translate from "../../translation/translate";
import mainbox from "./mainbox.vue";
import mainheader from "./mainheader.vue";
import mainfooter from "./mainfooter.vue";
import tariftable from "./tariftable.vue";
import mixins from "./../mixin";
import terms from "./terms.vue";
import archieveManager from "../components/archieveManager.vue";
import ChooseTariffModal from "./modal/ChooseTariffModal.vue";

export default {
  name: "price",
  data() {
    return {
      translation: translate,
    };
  },
  mixins: [mixins],
  components: {
    mainheader,
    mainbox,
    mainfooter,
    tariftable,
    terms,
    archieveManager,
    ChooseTariffModal,
  },
  metaInfo() {
    return {
      title: "Тарифы на использование Faktura.EDO",
      meta: [
        {
          name: "description",
          content:
            " Тарифы на использование Faktura.EDO ⏩ Месяц бесплатного пользования после регистрации. ☎️: +998 (71) 200-00-13.",
        },
      ],
    };
  },
};
</script>

<style>
body h3 {
  padding: 20px;
}

.packages .cards .card a {
  color: white;
  font-weight: 500 !important;
}

.packages {
  padding: 30px;
}

.packages .cards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
  width: 80%;
}

.packages .cards .card .summes {
  font-weight: 600;
}

.packages .card h4,
h5,
h6 {
  width: 100%;
  margin-top: 10px;
  font-weight: 800;
  color: #363636;
}

.packages .card h5 {
  margin-top: 20px;
}

.packages .card h6 {
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
}

.packages .card h4 {
  padding-top: 10px;
  margin: 0 !important;
  font-weight: 800;
}

.packages .card h6 {
  margin: 0;
  font-weight: 600;
}

.packages .card {
  width: 15% !important;
  min-width: 280px;
  height: 215px;
  text-align: center;
  background: #f7f7f7;
  box-shadow: rgb(40 40 40 / 20%) 0px 7px 29px 0px;
  border-radius: 10px;
  padding-bottom: 40px !important;
  margin-bottom: 50px;
  margin-right: 4%;
}

.packages input:hover {
  background-color: green;
  color: white;
  transition: 0.5s;
}

.packages input {
  margin-top: 8%;
  font-weight: 500;
  background-color: #45b411;
  color: white;
  padding: 7px 50px;
  border: none;
}

.packages #buyButton {
  margin-left: 15%;
  margin-right: 15%;
}

.packages .danger:hover {
  background-color: red;
  color: white;
  transition: 0.5s;
}

.packages .danger {
  font-weight: 500;
  background-color: #e74c3c;
  color: white;
  padding: 7px 50px;
  border: none;
  margin-bottom: 0px;
}

.icon-conditions {
  font-size: 50px;
  color: #72bd49;
}

div.text-condition {
  width: 70%;
  display: flex;
}

.conditions-block .text-condition h4 {
  padding-left: 10px;
  font-weight: 600;
}

li {
  padding-left: 20px;
}

ul {
  list-style-type: none;
}

.packages .card a {
  font-weight: 600 !important;
  color: black;
}

@media screen and (max-width: 414px) {
  .packages {
    width: 80%;
    height: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-left: auto;
    margin-right: auto;
  }

  .packages .cards {
    margin: 5px;
    display: flex;
  }

  .packages .card {
    width: 100% !important;
  }

  .packages input {
    padding: 8px 60px;
  }
}

@media screen and (max-width: 414px) {
  .packages {
    width: 100%;
    height: 100%;
    padding-right: 10px;
    padding-left: 5px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .packages input {
    padding: 8px 60px;
  }

  .table {
    width: 90%;
  }
}

@media screen and (max-width: 820px) {
  .packages .cards {
    width: 100%;
  }

  .table {
    width: 100%;
  }
}

@media screen and (max-width: 1540px) {
  .packages input {
    padding: 3px 20px;
    border: none;
  }

  .packages #buyButton {
    margin-left: 15%;
    margin-right: 15%;
    padding-bottom: 2%;
    padding-top: 2%;
  }

  .packages .card {
    width: 100%;
    text-align: center;
    border-radius: 10px;
  }
}

a {
  text-decoration: none !important;
}
</style>
